import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/logos/heart_pink.svg';
import pic2 from '../assets/logos/eye_blue.svg';
import pic3 from '../assets/logos/arrows.svg';
import claimWhite from '../assets/logos/claim_white.svg';
import logoGreen from '../assets/logos/logo_green_rgb.svg';
import labelWhite from '../assets/logos/label_white_rgb.svg';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <img src={logoGreen} alt="" className="logo" />
        <img src={claimWhite} alt="" className="claim" />
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Mehr erfahren
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style4 special">
      <div className="inner">
        <header className="major">
          <h2>Nina Barahona</h2>
          <p>
            Für dich da - vor, während und nach der Geburt
          </p>
          <a href="/AboutMe" className="button">
            Das bin ich...
          </a>
        </header>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>Bestärken</h2>
          <p>
            <em>
              "Wenn eine Frau gebärt, wird nicht nur ein Kind geboren, sondern
              auch eine Mutter. Die Art und Weise, wie wir mit ihr umgehen, wird
              beeinflussen, wie sie sich selbst als Mutter und Elternteil
              wahrnimmt. Sei sanft. Sei liebevoll. Höre ihr zu.
              <br />
              An jede Mutter da draussen, möge Deine Geburt wunderschön sein."<br />
            </em>
            <p>
              Ruth Ehrhardt
            </p>
          </p>
          <p>
            Ich möchte euch in dieser herausfordernden Zeit emotionale
            Sicherheit geben und dich als Frau bestärken, deinen eigenen und
            selbstbestimmten Weg zu gehen und in deine Urkraft zu vertrauen. Ich
            glaube an dich und bin da.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>Dienen</h2>
          <p>
            Doula bedeutet frei aus dem Griechischen übersetzt „Dienerin der
            Frau“. <br />
            Als deine Doula unterstütze ich dich und deinen Partner in der
            intimen Phase von Schwangerschaft, Geburt und Wochenbett und spanne
            so einen wichtigen Bogen über diese ganz besondere Zeit.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>Begleiten</h2>
          <p>
            Als eure Vertraute stehe ich euch zur Seite. Als Doula übernehme ich
            keine medizinische Funktion und kann dadurch auch während der Geburt
            vollends und ohne Unterbruch einfach für euch da sein.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Angebot</h2>
        </header>
        <ul className="features">
          <li className="icon fa-heart">
            <h3>Kennenlerngespräch</h3>
            <p>Unverbindliche Kontaktaufnahme und gegenseitiges Kennenlernen</p>
          </li>
          <li className="icon fa-heart">
            <h3>Vorgespräche</h3>
            <p>
              Während deiner Schwangerschaft treffen wir uns für zwei
              ausführliche Gespräche (ca. 1.5 h)
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Bereitschaft</h3>
            <p>
              Während vier Wochen rund um den Geburtstermin bin ich für euch auf
              Pikett
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Geburtsbegleitung</h3>
            <p>Während der gesamten Geburt bin ich lückenlos an eurer Seite</p>
          </li>
          <li className="icon fa-heart">
            <h3>Nachgespräche</h3>
            <p>
              Nach der Geburt stehe ich euch für ein bis zwei Nachgespräche und
              für gewünschte Unterstützung im Wochenbett zur Verfügung
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Deine Ansprechsperson</h3>
            <p>
              Du darfst dich während der gesamten Begleitungszeit bei mir melden
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="four" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>Packages</h2>
        </header>
        <ul className="packages">
          <li className="icon fa-heart">
            <h3>Begleitung «komplett»</h3>
            <p>
              •	Kennenlerngespräch<br/>
              •	2x Vorgespräche<br/>
              •	Geburtsbegleitung<br/>
              •	Wochenbettbesuch<br/>
              •	1x Nachgespräch<br/>
              •	Ansprechperson bei Fragen in der gesamten Begleitungszeit<br/>
              •	Ausleihen von Fachliteratur<br/>
              <br/>
              Pauschal: 1300.-
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Begleitung «light»</h3>
            <p>
            •	Kennenlerngespräch<br/>
            •	1x Vorgespräch<br/>
            •	Geburtsbegleitung<br/>
            •	1x Nachgespräch<br/>
            •	Ansprechperson bei Fragen in der gesamten Begleitungszeit<br/>
            •	Ausleihen von Fachliteratur<br/>
            <br/>
            Pauschal: 1000.-
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Gesprächs-Begleitung</h3>
            <p>
              •	Kennenlerngespräch <br/>
              •	2 Vorgespräche<br/>
              •	1 Nachgespräch/Wochenbettbesuch<br/>
              •	Ansprechperson bei Fragen in der gesamten Begleitungszeit<br/>
              <br/>
              Pauschal: 500.-
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Wochenbett-Begleitung</h3>
            <p>
              Unterstützung beim Zurechtkommen im neuen Alltag:<br/>
              •	Hilfe beim Umgang mit dem Neugeborenen <br/>
              •	Da-sein für eure Bedürfnisse und zuhören  <br/>
              •	Kleine Haushaltsarbeiten <br/>
              •	Betreuung älterer Geschwister <br/>
              •	Weitere Unterstützung nach individueller Absprache<br/>
              <br/>
              5 x 2h 500.- oder 60.-/h (mind. 2h pro Einsatz)
            </p>
          </li>
          <li className="icon fa-star">
            <h3>DoulaVida Gutschein</h3>
            <p>
              Jemand, der dir am Herzen liegt, erwartet ein Kind?
              Warum nicht ein bisschen Doula verschenken!<br />
              <br />
              Das etwas andere Geburtsgeschenk:<br />
              •	Beitrag an den Kosten einer Geburtsbegleitung<br/>
              •	Wochenbettunterstützun durch mich als Doula<br/>
            </p>
              Betrag und Gestaltung des Gutscheins sind frei wählbar<br/>
          </li>
        </ul>
        <p>
          <em>
            Alle Frauen/Paare, die sich eine Begleitung durch eine Doula wünschen, diese finanziell aber nicht stemmen können, sollen sich trotzdem bei mir melden.
            Zusammen finden wir eine Lösung.
          </em>
        </p>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Kontakt</h2>
          <p style={{ margin: 0 }}>Nina Barahona </p>
          <p style={{ margin: 0 }}>
            <a href="mailto:nina.barahona@doulavida.ch">
              nina.barahona@doulavida.ch
            </a>
          </p>
          <p style={{ margin: 0 }}>6005 | Luzern </p>
          <p>079 796 70 75</p>
        </header>
        <ul className="actions stacked">
          <li>
            <img src={labelWhite} alt="" />
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
